@import "_reset.css";
@import "_typography.css";

.givinglab-embed-wrapper {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1268px; /* matched GL page width of 1204px */
  padding: 1rem;
}

code {
  background: #eaeaea;
  border-radius: 3px;
  font-family: "Courier New", Courier, monospace;
  font-size: 0.9rem;
  font-weight: 600;
  margin: 0 0.25rem;
  padding: 0px 6px;
}
