body,
p,
li,
a,
span {
  font-family: Arial, Helvetica, sans-serif;
}

code,
pre {
  font-family: "Lucida Sans", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  line-height: 1.5rem;
}
